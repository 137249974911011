import { useEffect, useMemo, useState, useCallback } from 'react';
import clsx from 'clsx';
import ReactApexChart from 'react-apexcharts';
import { set, get } from 'idb-keyval';

import { baseModelScores } from './BaseModelEvaluations';
import HeatmapComponent from './HeatMapComponent';
import { tests } from './MixEvalTests';
import { useUser } from '../../../UserContext';

const MyModelsModelEvalResults = ({
  evaluationResults,
  newTestRequest,
  modelName,
  completedEvaluations,
  failedEvaluations,
  pendingEvaluations,
  setSelectedTests,
  startEvaluation,
}) => {
  const [selectedBaseModels, setSelectedBaseModels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [needleHaystackResults, setNeedleHaystackResults] = useState({});
  const [mixEvalResults, setMixEvalResults] = useState({});
  const [hasMixEval, setHasMixEval] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    if (
      'mix_eval' in evaluationResults &&
      Object.keys(evaluationResults['mix_eval']?.status).length > 0
    ) {
      setHasMixEval(true);
    }
  }, [evaluationResults]);

  // Fetch and set base models from IndexedDB
  useEffect(() => {
    const fetchData = async () => {
      const savedSelectedBaseModels = (await get('selectedBaseModels')) || [];
      const userModelData = savedSelectedBaseModels.find(
        (selectedModel) => selectedModel.user === user.id,
      );

      if (userModelData && userModelData.baseModels.length > 0) {
        setSelectedBaseModels(userModelData.baseModels);
      } else {
        const randomBaseModels = [];
        while (randomBaseModels.length < 4) {
          const randomIndex = Math.floor(
            Math.random() * baseModelScores.length,
          );
          const randomBaseModel = baseModelScores[randomIndex];
          if (!randomBaseModels.includes(randomBaseModel)) {
            randomBaseModels.push(randomBaseModel);
          }
        }
        setSelectedBaseModels(randomBaseModels);
      }

      // After setting the selected base models, mark loading as false
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    };

    fetchData();
  }, [user.id]);

  // Handle changes in evaluation results
  useEffect(() => {
    if (evaluationResults['mix_eval']) {
      const mixEvalCopy = JSON.parse(
        JSON.stringify(evaluationResults['mix_eval']),
      );
      if ('MBPP' in mixEvalCopy.results) {
        delete mixEvalCopy.results['MBPP'];
      }
      if ('Score average' in mixEvalCopy.results) {
        delete mixEvalCopy.results['Score average'];
      }

      if ('overall score (final score)' in mixEvalCopy.results) {
        const overallScore = mixEvalCopy.results['overall score (final score)'];
        delete mixEvalCopy.results['overall score (final score)'];
        mixEvalCopy.results = {
          'Overall Score': overallScore,
          ...mixEvalCopy.results,
        };
      }
      setMixEvalResults(mixEvalCopy);
    }

    if (evaluationResults['needlehaystack']) {
      setNeedleHaystackResults(evaluationResults['needlehaystack']);
    }
  }, [evaluationResults]);

  // Generate chart data based on the mixEval results and selected base models
  useEffect(() => {
    if (mixEvalResults?.results) {
      const chartData = Object.keys(mixEvalResults?.results).map((metric) => {
        if (!completedEvaluations?.includes(metric)) {
          return null;
        }
        const data = {
          name: metric,
          data: [
            {
              x: 'Model',
              y:
                Math.round(
                  (mixEvalResults.results[metric] + Number.EPSILON) * 100,
                ) / 100,
            },
            ...selectedBaseModels.map((baseModel) => ({
              x: baseModel.displayName,
              y:
                Math.round((baseModel.scores[metric] + Number.EPSILON) * 100) /
                100,
            })),
          ],
        };
        return data;
      });
      setChartData(chartData.filter((data) => data));
    }
  }, [mixEvalResults, selectedBaseModels, completedEvaluations]);

  const handleSelectBaseModels = useCallback(
    async (baseModel) => {
      let selectedModelsInternal = [...selectedBaseModels];
      if (selectedModelsInternal.some((model) => model.id === baseModel.id)) {
        selectedModelsInternal = selectedModelsInternal.filter(
          (model) => model.id !== baseModel.id,
        );
      } else {
        selectedModelsInternal.push(baseModel);
      }
      setSelectedBaseModels(selectedModelsInternal);

      const savedModels = (await get('selectedBaseModels')) || [];
      const otherUsersModels = savedModels.filter(
        (model) => model.user !== user.id,
      );
      set('selectedBaseModels', [
        ...otherUsersModels,
        { user: user.id, baseModels: selectedModelsInternal },
      ]);
    },
    [selectedBaseModels, user.id],
  );

  const getIntroductionText = (evaluationResults) => {
    const hasMixEval =
      evaluationResults['mix_eval'] &&
      Object.keys(evaluationResults['mix_eval'].status).length > 0;
    const hasNeedleHaystack =
      evaluationResults?.needlehaystack?.status?.trim?.() === 'not_started'
        ? false
        : Boolean(evaluationResults?.needlehaystack?.status);

    
    if (hasMixEval && hasNeedleHaystack) {
      return "This evaluation compares the performance of your fine-tuned model against the selected models using a series of standard metrics. Each metric evaluates the model's ability to perform specific tasks or answer particular types of questions.";
    } else if (hasNeedleHaystack) {
      return "This evaluation focuses on the needle in a haystack task, measuring the model's accuracy and efficiency in identifying relevant information from a large dataset.";
    } else if (hasMixEval) {
      return 'This evaluation assesses your model using a mix of standard metrics to determine its overall performance and effectiveness in handling various tasks.';
    } else {
      return 'No evaluation results available. Please provide either needle in a haystack or mix evaluation results.';
    }
  };

  const introductionText = useMemo(
    () => getIntroductionText(evaluationResults),
    [evaluationResults],
  );

  const handleRetryAll = useCallback(() => {
    setSelectedTests(failedEvaluations);
    startEvaluation({
      model_name: modelName,
      eval_type: [],
      benchmarks_to_keep: [...new Set(failedEvaluations)],
    });
  }, [failedEvaluations, modelName, setSelectedTests, startEvaluation]);

  return (
    <div className="mt-4 h-full overflow-x-hidden">
      {!isLoading && (
        <>
          <div className="mb-4 text-gray-500 text-sm xl:text-base font-normal">
            {/* <h1 className="xl:text-2xl text-lg font-bold mb-2">
              Model Evaluation Results
            </h1> */}
            <p className="mb-4">{introductionText}</p>
          </div>

          <div className="flex gap-x-2 mx-auto w-full flex-wrap">
            {hasMixEval &&
              baseModelScores.map((baseModel) => {
                const isChecked = selectedBaseModels.some(
                  (selectedBaseModel) => selectedBaseModel.id === baseModel.id,
                );
                return (
                  <div
                    key={baseModel.id}
                    className="flex items-center mb-2 border rounded px-2 py-1 border-gray-300 w-fit text-sm"
                  >
                    <input
                      type="checkbox"
                      aria-checked={isChecked}
                      id={baseModel.name}
                      name={baseModel.name}
                      checked={isChecked}
                      className={clsx(
                        'mr-2 text-zinc-800 focus:ring-0 focus:ring-indigo-500 focus:ring-offset-0 focus:ring-offset-indigo-500',
                      )}
                      onChange={() => handleSelectBaseModels(baseModel)}
                    />
                    <label htmlFor={baseModel.name} className="mr-2">
                      {baseModel.displayName}
                    </label>
                  </div>
                );
              })}
          </div>

          <div className="grid grid-cols-1 gap-4 mt-4 w-full md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 auto-rows-fr">
            {chartData.map((data) => (
              <div key={data?.name} className="border rounded-lg p-4 bg-white">
                <div className="flex flex-col">
                  <h2 className="text-lg font-semibold mb-2">{data?.name}</h2>
                  <ReactApexChart
                    options={{
                      chart: {
                        type: 'bar',
                        height: 300,
                        stacked: false,
                        animations: {
                          enabled: false,
                        },
                        toolbar: {
                          show: false,
                        },
                      },
                      plotOptions: {
                        bar: {
                          horizontal: false,
                          distributed: true,
                          dataLabels: {
                            position: 'bottom',
                          },
                          borderRadius: 5,
                          borderRadiusApplication: 'end',
                        },
                      },
                      colors: ['#9CA3AF', '#818CF8', '#E4E4E7', '#C7D2FE'],
                      dataLabels: {
                        enabled: true,
                        style: {
                          colors: ['#000'],
                        },
                      },
                      stroke: {
                        width: 1,
                        colors: ['#fff'],
                      },
                      xaxis: {
                        categories: [
                          modelName.length > 18
                            ? `${modelName.slice(0, 18)}...`
                            : modelName,
                          ...selectedBaseModels.map(
                            (baseModel) => baseModel.displayName,
                          ),
                        ],
                        min: 0,
                        max: 1,
                        position: 'bottom',
                        labels: {
                          style: {
                            colors: '#333',
                          },
                        },
                      },
                      yaxis: {
                        title: {
                          text: 'Score',
                        },
                        labels: {
                          style: {
                            colors: '#333',
                          },
                        },
                        min: 0,
                        max: 1,
                        tickAmount: 5,
                      },
                      tooltip: {
                        y: {
                          formatter: function (val) {
                            return val.toFixed(2);
                          },
                        },
                      },
                      fill: {
                        opacity: 1,
                      },
                      legend: {
                        show: false,
                        position: 'top',
                        horizontalAlign: 'left',
                        offsetX: 40,
                      },
                    }}
                    series={[
                      {
                        name: 'Score',
                        data: data?.data,
                      },
                    ]}
                    type="bar"
                    height={300}
                  />
                </div>
                <p className="text-sm text-gray-500 mt-4">
                  {data?.name === 'Overall Score' &&
                    'The overall score is a composite of the individual test scores. It provides a general measure of the model’s performance across all tasks.'}
                  {
                    tests.find(
                      (test) =>
                        test.name.toLowerCase() === data?.name.toLowerCase(),
                    )?.description
                  }
                </p>
              </div>
            ))}

            {needleHaystackResults?.status === 'complete' &&
              needleHaystackResults?.results &&
              'scores' in needleHaystackResults.results && (
                <HeatmapComponent
                  matrix={needleHaystackResults?.results?.scores}
                  x_axis={needleHaystackResults?.results?.x_axis}
                  y_axis={needleHaystackResults?.results?.y_axis}
                />
              )}
            {pendingEvaluations.length > 0 && (
              <div className="border rounded-lg p-4">
                <div className="flex flex-col h-full">
                  <h2 className="text-lg font-bold mb-2">Pending Tests</h2>
                  <ul className="list-disc list-inside mb-4 text-sm xl:text-base">
                    {pendingEvaluations.map((metric) => (
                      <li key={metric}>
                        <strong>
                          {
                            tests.find(
                              (test) =>
                                test.name.toLowerCase() ===
                                metric.toLowerCase(),
                            )?.displayName
                          }
                          :
                        </strong>{' '}
                        Pending
                      </li>
                    ))}
                  </ul>
                  <div className="grow"></div>
                  <div className="text-sm text-gray-500 lg:pb-5">
                    <p>
                      The tests above were requested but are not yet complete.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {failedEvaluations.length > 0 && (
              <div className="border rounded-lg p-4 relative">
                <button
                  className="absolute top-2 right-4 border border-zinc-300 shadow rounded-md px-2 py-1 bg-zinc-200 hover:bg-zinc-300 active:bg-zinc-200 text-zinc-900 text-sm
                  active:shadow-none disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-zinc-200"
                  onClick={handleRetryAll}
                >
                  Retry all
                </button>
                <div className="flex flex-col h-full">
                  <h2 className="text-lg font-bold mb-2">Failed Tests</h2>
                  <ul className="list-disc list-inside mb-4 text-sm xl:text-base">
                    {failedEvaluations.map((metric) => (
                      <li key={metric}>
                        <strong>
                          {
                            tests.find(
                              (test) =>
                                test.name.toLowerCase() ===
                                metric.toLowerCase(),
                            )?.displayName
                          }
                          :
                        </strong>{' '}
                        Failed
                      </li>
                    ))}
                  </ul>
                  <div className="grow"></div>
                  <div className="text-sm text-gray-500 lg:pb-5">
                    <p>
                      The tests above failed to complete successfully. You may
                      want to retry these tests.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {needleHaystackResults?.results &&
          'scores' in needleHaystackResults.results ? (
            <div className="my-12">
              <h2 className="xl:text-xl text-lg font-bold mb-2">
                Needle in a Haystack Test
              </h2>
              <p>
                The Needle in a Haystack Test evaluates the model's ability to
                find the correct answer in a sea of incorrect answers. The
                matrix above shows the percentage of correct answers found by
                the model for each haystack size (context size) and the location
                of the needle in the haystack (depth of the document). Higher
                percentages indicate better performance.
              </p>
            </div>
          ) : evaluationResults?.needlehaystack?.status === 'started' ? (
            <div className="my-12">
              <h1 className="xl:text-2xl text-lg font-bold mb-2">
                Needle in a Haystack Test
              </h1>
              <p>
                The Needle in a Haystack Test evaluates the model's ability to
                find the correct answer in a sea of incorrect answers. The test
                is currently in progress. Please check back later for the
                results.
              </p>
            </div>
          ) : null}

          {hasMixEval && (
            <>
              <h2 className=" font-medium mb-1 mt-12">
                General Interpretation Tips
              </h2>
              <ul className="list-disc list-inside mb-4 text-sm text-gray-500">
                <li>
                  <strong>High Scores:</strong> Indicate strong performance in
                  the corresponding task. For instance, a high score in PIQA
                  (0.75) suggests good understanding of physical interactions.
                </li>
                <li>
                  <strong>Low Scores:</strong> Suggest areas where the model
                  struggles. For example, a score of 0.2 in MBPP or SIQA
                  indicates that the model had significant difficulty or failed
                  completely in these tasks.
                </li>
                <li>
                  <strong>Balanced Scores:</strong> If scores across different
                  tasks are fairly balanced, it suggests the model has a
                  well-rounded performance. Large disparities may indicate
                  specific strengths and weaknesses.
                </li>
                <li>
                  <strong>Improvement Areas:</strong> Low-scoring tasks can be
                  targeted for model improvements. For example, if CommonsenseQA
                  is low, you might need to improve the model's commonsense
                  reasoning capabilities.
                </li>
              </ul>
              <h2 className=" font-medium mb-1">Methodology</h2>
              <p className="mb-4 text-sm text-gray-500">
                The evaluation process involves running the selected model
                through a series of tests corresponding to each metric. The
                scores reflect the model's ability to accurately answer
                questions or solve problems within each category. The results
                are used to identify areas where the model outperforms the base
                models and areas needing improvement.
              </p>
            </>
          )}
          {mixEvalResults?.results === 'started' ? (
            <div className="my-12">
              <h2 className="xl:text-xl text-lg font-bold mb-2">
                MixEval Results
              </h2>
              <p>
                The MixEval test evaluates the model's performance across a
                range of selected tasks. The tests are currently in progress.
                Please check back later for the results.
              </p>
            </div>
          ) : null}

          {/* <button
            onClick={newTestRequest}
            className="flex items-center justify-center w-full h-12 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100 disabled:opacity-50 max-w-lg mx-auto disabled:cursor-not-allowed lg:my-32"
          >
            Request New Evaluations
          </button> */}
        </>
      )}
    </div>
  );
};

export default MyModelsModelEvalResults;
