import { Fragment, useEffect, useState } from 'react';
import './styles/flatpickr.css';
import Flatpickr from 'react-flatpickr';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import toast from 'react-hot-toast';

import AddTagsModal from './AddTagsModal';

const FiltersArea = ({
  modelFilters,
  tagFilters,
  dateRangeFilters,
  filterActions,
  filteredLogIds,
}) => {
  const [isAddTagsModalOpen, setIsAddTagsModalOpen] = useState(false);

  const handleModelChange = (models) => {
    modelFilters.setSelectedModels(models);
    filterActions.handleApplyFilters();
  };

  const handleTagChange = (tags) => {
    tagFilters.setSelectedTags(tags);
    filterActions.handleApplyFilters();
  };

  useEffect(() => {
    filterActions.handleApplyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modelFilters.selectedModels,
    tagFilters.selectedTags,
    dateRangeFilters.selectedDateRange,
  ]);

  return (
    <div className="flex flex-col justify-between w-full p-8 pb-2 mb-8 bg-white drop-shadow">
      <div>
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Filters
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Construct a dataset as a subset of your logs by applying specific
            filters.
          </p>
        </div>
      </div>
      <div className="flex justify-center w-full pt-8 pb-8">
        <div className="flex flex-wrap justify-start w-full gap-6">
          <Listbox
            as="div"
            value={modelFilters.selectedModels}
            onChange={handleModelChange} // Automatically apply filters when models change
            multiple
          >
            <Listbox.Button className="text-zinc-700 font-medium text-sm px-5 py-1.5 text-center inline-flex items-center w-52 justify-between border-b">
              {modelFilters.selectedModels.length > 1
                ? modelFilters.selectedModels.length + ' Models Selected'
                : modelFilters.selectedModels.length === 1
                  ? modelFilters.selectedModels[0]
                  : 'Models'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 p-2 mt-1 bg-white rounded shadow w-52 dark:bg-gray-700 dark:divide-gray-600">
              {modelFilters.uniqueModels.map((model) => (
                <Listbox.Option key={model} value={model} as={Fragment}>
                  {({ selected }) => (
                    <li
                      className={` flex gap-2 items-center px-1 my-2 bg-white text-gray-900 text-sm`}
                      aria-selected={selected}
                    >
                      {selected ? (
                        <div className="w-4 h-4 border-gray-300 rounded bg-zinc-800 focus:ring-indigo-500 focus:ring-2 flex-center">
                          <CheckIcon
                            className="w-[10px] h-[10px] text-gray-100 font-bold"
                            strokeWidth="4"
                          />
                        </div>
                      ) : (
                        <div className="w-4 h-4 text-indigo-600 bg-gray-100 border border-gray-300 rounded focus:ring-indigo-500 focus:ring-2"></div>
                      )}
                      {model}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>

          <Listbox
            as="div"
            value={tagFilters.selectedTags}
            onChange={handleTagChange} // Automatically apply filters when tags change
            multiple
          >
            <Listbox.Button className="text-zinc-700 font-medium text-sm px-5 py-1.5 text-center inline-flex items-center w-52 justify-between border-b">
              {tagFilters.selectedTags.length > 1
                ? tagFilters.selectedTags.length + ' Tags Selected'
                : tagFilters.selectedTags.length === 1
                  ? tagFilters.selectedTags[0]
                  : 'Tags'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 p-2 mt-1 overflow-y-scroll bg-white rounded shadow w-52 dark:bg-gray-700 dark:divide-gray-600 max-h-40">
              {tagFilters.uniqueTags.map((tag) => (
                <Listbox.Option key={tag} value={tag} as={Fragment}>
                  {({ selected }) => (
                    <li
                      className={` flex gap-2 items-center px-1 my-2 bg-white text-gray-900 text-sm`}
                      aria-selected={selected}
                    >
                      {selected ? (
                        <div className="w-4 h-4 border-gray-300 rounded bg-zinc-800 focus:ring-indigo-500 focus:ring-2 flex-center">
                          <CheckIcon
                            className="w-[10px] h-[10px] text-gray-100 font-bold"
                            strokeWidth="4"
                          />
                        </div>
                      ) : (
                        <div className="w-4 h-4 text-indigo-600 bg-gray-100 border border-gray-300 rounded focus:ring-indigo-500 focus:ring-2"></div>
                      )}
                      {tag}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>

          {/* Date Range Filter */}
          <div className="relative" ref={dateRangeFilters.dateRangeDropdownRef}>
            <button
              id="dropdownDateRangeButton"
              onClick={() =>
                dateRangeFilters.setIsDateRangeDropdownOpen(
                  !dateRangeFilters.isDateRangeDropdownOpen,
                )
              }
              className="text-zinc-700 font-medium text-sm px-5 py-1.5 text-center inline-flex items-center w-52 justify-between border-b"
              type="button"
            >
              {dateRangeFilters.selectedDateRange.start === '' &&
              dateRangeFilters.selectedDateRange.end === ''
                ? 'Date Range'
                : 'Date Range Selected'}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {dateRangeFilters.isDateRangeDropdownOpen && (
              <div
                id="dropdownDateRange"
                className="absolute z-10 p-2 mt-1 bg-white divide-y divide-gray-100 rounded shadow w-52 dark:bg-gray-700 dark:divide-gray-600"
              >
                <div className="relative py-2">
                  <button
                    className="absolute top-0 right-0 text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-0"
                    aria-label="Clear Date Range"
                    onClick={() => {
                      dateRangeFilters.setSelectedDateRange({
                        start: '',
                        end: '',
                      });
                      filterActions.handleApplyFilters(); // Apply filters
                    }}
                  >
                    Clear
                  </button>
                  <label
                    htmlFor="start-date"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Start Date:
                  </label>
                  <Flatpickr
                    ref={dateRangeFilters.startDateRef}
                    value={dateRangeFilters.selectedDateRange.start}
                    onChange={(date) =>
                      dateRangeFilters.handleDateChange('start', date)
                    }
                    options={{
                      altInput: true,
                      altFormat: 'F j, Y h:i K',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      maxDate: new Date(),
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                  />
                </div>
                <div className="py-2">
                  <label
                    htmlFor="end-date"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    End Date:
                  </label>
                  <Flatpickr
                    ref={dateRangeFilters.endDateRef}
                    value={dateRangeFilters.selectedDateRange.end}
                    onChange={(date) =>
                      dateRangeFilters.handleDateChange('end', date)
                    }
                    options={{
                      altInput: true,
                      altFormat: 'F j, Y h:i K',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      maxDate: new Date(),
                      defaultDate: 'today',
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 "
                  />
                </div>
              </div>
            )}
          </div>
          <button
            onClick={filterActions.handleClearFilters}
            className="px-2 text-sm font-medium border rounded-md shadow-sm text-zinc-600 bg-zinc-50 hover:bg-zinc-200"
          >
            Clear Filters
          </button>
        </div>
      </div>

      {/* Selected Filters Section */}
      {(modelFilters.selectedModels.length > 0 ||
        tagFilters.selectedTags.length > 0 ||
        dateRangeFilters.selectedDateRange.start !== '' ||
        dateRangeFilters.selectedDateRange.end !== '') && (
        <div className="flex items-center py-4 mt-4 ">
          <div className="flex items-center text-sm font-semibold text-gray-700 ">
            Filters{' '}
            <div
              aria-hidden="true"
              className="hidden w-px h-5 mr-4 bg-gray-300 sm:ml-4 sm:block"
            />
          </div>
          <div className="flex flex-wrap items-center gap-2 drop-shadow-sm">
            {modelFilters.selectedModels.map((model) => (
              <span
                key={model}
                className="flex px-3 py-1 text-sm font-medium border border-indigo-200 rounded-full text-zinc-700 bg-zinc-50/50"
              >
                {model}
                <button
                  onClick={() => {
                    const newModels = modelFilters.selectedModels.filter(
                      (m) => m !== model,
                    );
                    modelFilters.setSelectedModels(newModels);
                    filterActions.handleApplyFilters();
                  }}
                  className="ml-2 text-gray-500 hover:text-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </span>
            ))}
            {tagFilters.selectedTags.map((tag) => (
              <span
                key={tag}
                className="flex px-3 py-1 text-sm font-medium border border-indigo-200 rounded-full text-zinc-700 bg-zinc-50/50"
              >
                {tag}
                <button
                  onClick={() => {
                    const newTags = tagFilters.selectedTags.filter(
                      (t) => t !== tag,
                    );
                    tagFilters.setSelectedTags(newTags);
                    filterActions.handleApplyFilters();
                  }}
                  className="ml-2 text-gray-500 hover:text-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </span>
            ))}
            {dateRangeFilters.selectedDateRange.start !== '' && (
              <span className="flex px-3 py-1 text-sm font-medium border border-indigo-200 rounded-full text-zinc-700 bg-zinc-50/50">
                Start:{' '}
                {new Date(
                  dateRangeFilters.selectedDateRange.start,
                ).toLocaleDateString()}
                <button
                  onClick={() => {
                    dateRangeFilters.setSelectedDateRange((prev) => ({
                      ...prev,
                      start: '',
                    }));
                    filterActions.handleApplyFilters();
                  }}
                  className="ml-2 text-gray-500 hover:text-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </span>
            )}
            {dateRangeFilters.selectedDateRange.end !== '' && (
              <span className="flex px-3 py-1 text-sm font-medium border border-indigo-200 rounded-full text-zinc-700 bg-zinc-50/50">
                End:{' '}
                {new Date(
                  dateRangeFilters.selectedDateRange.end,
                ).toLocaleDateString()}
                <button
                  onClick={() => {
                    dateRangeFilters.setSelectedDateRange((prev) => ({
                      ...prev,
                      end: '',
                    }));
                    filterActions.handleApplyFilters();
                  }}
                  className="ml-2 text-gray-500 hover:text-red-600"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-3"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18 18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </span>
            )}
          </div>
        </div>
      )}

      <div className="flex flex-col pt-6 mt-4 mb-6 border-t md:flex-row justify-left gap-x-4 gap-y-2">
        <button
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center rounded-md shadow text-zinc-50 hover:bg-zinc-700 focus:ring-0 focus:outline-none focus:ring-indigo-300 justify-evenly bg-zinc-800 max-w-48"
          onClick={() => filterActions.handleCreateDataset()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-2 size-5 text-zinc-100"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
            />
          </svg>
          Create dataset
        </button>
        <button
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-zinc-500 hover:text-zinc-900 justify-evenly max-w-48"
          onClick={() => {
            if (filteredLogIds.length > 0) {
              setIsAddTagsModalOpen(true);
            } else {
              toast.error('No logs selected');
            }
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-2 size-5 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Add tags
        </button>
        <button
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-zinc-500 hover:text-zinc-900 justify-evenly max-w-48"
          onClick={filterActions.handleSynthesizeData}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-2 size-5 "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
            />
            x
          </svg>
          Synthesize logs
        </button>
        <button
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-zinc-500 hover:text-zinc-900 justify-evenly max-w-48"
          onClick={filterActions.handleDeleteLogs}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-2 size-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
            />
          </svg>
          Delete logs
        </button>
      </div>
      <AddTagsModal
        isOpen={isAddTagsModalOpen}
        setIsOpen={setIsAddTagsModalOpen}
        tags={tagFilters.selectedTags}
        setTags={tagFilters.setSelectedTags}
        rowIndex={null}
        logIds={filteredLogIds}
        fetchLogs={filterActions.handleApplyFilters}
      />
    </div>
  );
};

export default FiltersArea;
