import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

import { useUser } from '../../../UserContext';

const StopTrainingButton = ({ model }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customAxios } = useUser();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const handleEndTraining = async (model) => {
    setLoading(true);
    try {
      const response = await customAxios.post('tailor/v1/training-job/end', {
        model_name: model,
      });

      if (response.status === 200) {
        toast.success('Training ended successfully');
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      toast.error('Failed to end training, try again later.');
    } finally {
      setLoading(false);
      closeModal();
    }
  };

  return (
    <div className="text-right ">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white rounded-lg shadow-xl">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-zinc-900"
                  >
                    Are you sure?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-zinc-600">
                      Are you sure you want to end training? This action cannot
                      be undone.
                    </p>
                    <p className="mt-4 text-sm text-zinc-600">
                      This will end the job and discard any training data.
                      <br />
                      It will not delete any logs
                    </p>
                  </div>

                  <div className="flex justify-end gap-4 mt-8">
                    <button
                      className="flex items-center gap-1 p-2 px-3 text-sm font-bold underline rounded-md text-zinc-700 hover:underline underline-offset-4"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="flex items-center gap-1 p-2 px-3 text-sm font-bold bg-red-200 rounded-md text-zinc-700 hover:bg-red-300 active:bg-red-400 disabled:bg-red-100 disabled:text-zinc-400"
                      onClick={() => handleEndTraining(model.model_name)}
                      disabled={loading}
                    >
                      Cancel Training
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <button
        className="inline-flex items-center justify-center h-8 px-4 ml-2 text-sm font-medium text-red-900 border border-gray-300 rounded-md whitespace-nowrap hover:bg-red-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2 active:bg-red-100"
        onClick={openModal}
      >
        Cancel Training
      </button>
    </div>
  );
};

export default StopTrainingButton;
