import React, { useState } from 'react';
import Breadcrumbs from './MyModels/Breadcrumbs';
import { useLocation, useParams } from 'react-router-dom';
import { QueryErrorResetBoundary, useQuery } from '@tanstack/react-query';
import { useUser } from '../../UserContext';
import TokenUsageChart from './ModelDetail/TokenUsageChart';
import ModelNotFound from './MyModels/ModelNotFound';
import UsageDataCard from './ModelDetail/UsageDataCard';
import EvaluationsCard from './ModelDetail/Evaluationscard';
import { useGetBaseModels, useGetModels } from '../../Hooks/react-query';
import clsx from 'clsx';
import DeployCard from './ModelDetail/DeployCard';
import { Suspense, useEffect } from 'react';
import ModelDetailSkeleton from './ModelDetail/ModelDetailSkeleton';
import { ErrorBoundary } from 'react-error-boundary';
// import { useUser } from '../../UserContext';
// import { getInitialDisplayDate } from '../../utils/generalUtils';
import { useAtom } from 'jotai';
import { modelStateAtom } from '../../context/atoms';
import {
  deployedSwitchStates,
  dormantSwitchStates,
  failedSwitchStates,
  trainingSwitchStates,
} from './Deploy/modelStateConstants';
import TrainingMetricsCard from './ModelDetail/TrainingMetricsCard';
import PipelinesCard from './ModelDetail/PipelinesCard';
import { toast } from 'react-hot-toast';
import { Tooltip } from 'flowbite-react';
import Spinner from '../Spinner';

const TailorModelDetail = () => {
  const { pathname } = useLocation();
  const isBaseModel = pathname.split('/').slice(-2, -1)[0] === 'base';
  const { model_name } = useParams();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={() => (
            <div className="flex items-center justify-center ">
              <div className="text-center">
                <button
                  onClick={() => reset()}
                  className="px-4 py-2 bg-red-100 text-red-700 rounded-md hover:bg-red-200"
                >
                  Try again
                </button>
              </div>
            </div>
          )}
          onReset={reset}
        >
          <Suspense fallback={<ModelDetailSkeleton />}>
            <ModelDetailContent
              isBaseModel={isBaseModel}
              modelName={model_name}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

const DownloadModal = ({ isOpen, onClose, downloadLinks, model }) => {
  if (!isOpen || !downloadLinks) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Modal Overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal Content */}
      <div className="bg-zinc-50 rounded-lg  relative z-10 max-w-md w-full">
        {/* Close Button */}
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
          onClick={onClose}
        >
          {/* Close Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <h2 className="text-zinc-800 font-medium px-6 py-3 ">
          Download {model.model_name} Files
        </h2>
        <div className="w-full border-b mb-4 shadow"></div>
        <ul className="space-y-3 px-6 pb-6">
          {Object.entries(downloadLinks).map(([fileName, fileUrl]) => {
            // Handle file renaming if necessary
            const downloadFileName =
              fileName === 'adapter_model'
                ? 'adapter_model.safetensors'
                : fileName;

            return (
              <li key={fileName}>
                <a
                  href={fileUrl}
                  download={downloadFileName}
                  className="text-zinc-600 text-sm font-light hover:underline flex flex-row items-center gap-2  px-2 rounded-md w-64 py-2  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1"
                    stroke="currentColor"
                    className="size-7 text-indigo-700"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                    />
                  </svg>{' '}
                  {downloadFileName}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const ModelDetailContent = ({ isBaseModel, modelName }) => {
  const { data: models, error: modelsError } = useGetModels();
  let { data: baseModels, error: baseModelsError } = useGetBaseModels();
  const [modelState, setModelState] = useAtom(modelStateAtom);
  const { customAxios } = useUser();
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadLinks, setDownloadLinks] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDownload = async (e) => {
    e.stopPropagation();
    setIsDownloading(true);

    try {
      // Fetch the URLs from your API
      const response = await customAxios.post(
        '/tailor/v1/download_model_files',
        {
          model_name: modelName,
        },
      );

      const urls = response.data.urls;

      // Set the download links state and open the modal
      setDownloadLinks(urls);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching download links:', error);
      toast.error(
        'Failed to retrieve the download links, please try again later.',
      );
    } finally {
      setIsDownloading(false);
    }
  };

  // Throw error to trigger error boundary
  if (modelsError || baseModelsError) {
    throw modelsError || baseModelsError;
  }

  const model = isBaseModel
    ? baseModels?.find((m) => m.model_name === modelName)
    : models?.find((m) => m.model_name === modelName);

  const modelId = model?.model_id;
  const currentModelState = modelState[modelId];

  useEffect(() => {
    if (currentModelState) {
      return;
    }
    setModelState((prev) => ({ ...prev, [modelId]: model.state }));
  }, [model, currentModelState, modelId, setModelState]);

  if (!model) {
    return <ModelNotFound />;
  }

  const showUsageDataCards =
    isBaseModel ||
    deployedSwitchStates.includes(currentModelState?.toLowerCase()) ||
    (dormantSwitchStates.includes(currentModelState?.toLowerCase()) &&
      model?.deployment_records.length > 0);

  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans">
      <header className="sticky top-0 bg-zinc-50/95 backdrop-blur-sm z-50 border-b border-zinc-200">
        <div className="mx-auto w-full">
          <div
            className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8"
            role="banner"
          >
            <Breadcrumbs />
            <div className="flex items-center gap-2 lg:gap-6" />
          </div>
        </div>
      </header>

      <main className="w-full max-w-7xl mx-auto px-4 py-6 space-y-6">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 lg:gap-6 lg:auto-rows-min grid-flow-row">
          <div
            id="1"
            className="row-span-1 bg-white rounded-lg shadow-sm border border-zinc-200 p-4 relative overflow-hidden flex flex-col h-full"
          >
            <h3 className="text-xl font-bold text-zinc-800 mb-4 flex flex-row items-center text-wrap break-words ">
              {isBaseModel ? (
                <>
                  <img
                    src={model?.image_url}
                    alt={model?.family}
                    className="w-auto h-5 mr-2"
                  />
                  {model?.display_name}{' '}
                </>
              ) : (
                <>
                  <div>
                    {model?.model_name}{' '}
                    <button
                      className="mt-2 px-1 py-0.5 text-sm font-medium text-black hover:text-zinc-700 hover:border-zinc-800 border border-transparent hover:shadow rounded disabled:opacity-50 disabled:cursor-not-allowed z-10"
                      onClick={handleDownload}
                      disabled={isDownloading}
                    >
                      <Tooltip
                        className="text-xs bg-zinc-900 text-zinc-200"
                        content={`Download ${model.model_name}`}
                        style="dark"
                        arrow={false}
                      >
                        {isDownloading ? (
                          <span>
                            <Spinner
                              size="16px"
                              borderColor="#F4F4F5"
                              borderTopColor="black"
                            />
                          </span>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="size-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                        )}
                      </Tooltip>
                    </button>
                  </div>
                </>
              )}
            </h3>

            <dl className="space-y-3 flex-grow flex flex-col">
              {!isBaseModel && (
                <div className="flex flex-col gap-1">
                  <dt className="text-sm text-zinc-500">Model Architecture</dt>
                  <dd className="text-sm font-medium text-zinc-900">
                    {model?.model_config?.base_model}
                  </dd>
                </div>
              )}
              <div className="flex-grow"></div>
              {isBaseModel ? (
                <div className="flex flex-col gap-1">
                  <dt className="text-xs text-zinc-500">Base Model</dt>
                </div>
              ) : (
                <div className="flex justify-between w-full mr-14">
                  <div className="flex flex-col gap-1">
                    <dt className="text-xs text-zinc-500">Creation Date</dt>
                    <dd className="text-xs font-medium text-zinc-900">
                      {new Date(model?.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </dd>
                  </div>
                  {model?.deployment_records.length > 0 ? (
                    <div className="flex flex-col gap-1 z-20 mr-14">
                      <dt className="text-xs text-zinc-500">Last Deployed</dt>
                      <dd className="text-xs font-medium text-zinc-900">
                        {new Date(
                          model.deployment_records[
                            model.deployment_records.length - 1
                          ][0],
                        ).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </dd>
                    </div>
                  ) : null}
                </div>
              )}
            </dl>
            <div
              className={clsx(
                'absolute p-32 text-xs font-semibold rounded-lg top-10 -right-48 shadow-inner',
                (deployedSwitchStates.includes(
                  currentModelState?.toLowerCase(),
                ) ||
                  isBaseModel) &&
                  'bg-gradient-to-bl from-transparent via-green-50 to-green-100',
                trainingSwitchStates.includes(
                  currentModelState?.toLowerCase(),
                ) &&
                  'bg-gradient-to-bl from-transparent via-blue-50 to-blue-100 border-blue-300',
                dormantSwitchStates.includes(
                  currentModelState?.toLowerCase(),
                ) &&
                  'bg-gradient-to-bl from-transparent via-orange-50 to-orange-100 border-orange-300',
                failedSwitchStates.includes(currentModelState?.toLowerCase()) &&
                  'bg-gray-50 border-gray-300',
              )}
            ></div>
          </div>

          {!isBaseModel && (
            <div
              id="3"
              className="lg:col-span-1 row-span-1 lg:row-start-2 lg:col-start-1 lg:order-3 order-2 h-auto lg:h-24"
            >
              <DeployCard model={model} />
            </div>
          )}

          {showUsageDataCards && (
            <div
              id="2"
              className="lg:row-span-1 lg:col-span-2 h-fit lg:order-2 order-3"
            >
              <UsageDataCard baseModel={model?.model_config?.base_model} />
            </div>
          )}
          {showUsageDataCards && (
            <div
              id="4"
              className="lg:col-span-1 lg:row-span-2 lg:order-3 order-2"
            >
              <EvaluationsCard isBaseModel={isBaseModel} />
            </div>
          )}
          {showUsageDataCards && (
            <div
              id="5"
              className={clsx(
                'lg:col-span-2 lg:row-start-2 lg:col-start-2 lg:row-span-3 bg-white rounded-lg shadow-sm border border-zinc-200 p-4 order-5',
              )}
            >
              <TokenUsageChart model={model} />
            </div>
          )}
          {showUsageDataCards && false && (
            <div
              id="6"
              className="lg:col-span-3 lg:row-start-5 lg:col-start-1 lg:row-span-1 order-6"
            >
              <PipelinesCard model={model} />
            </div>
          )}
          {!showUsageDataCards && (
            <div
              id="5"
              className="lg:col-span-2 lg:row-start-1 lg:col-start-2 lg:row-span-12 bg-white rounded-lg shadow-sm border border-zinc-200 p-4 lg:order-2 order-none "
            >
              <TrainingMetricsCard model={model} />
            </div>
          )}
        </div>
      </main>
      <DownloadModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        downloadLinks={downloadLinks}
        model={model}
      />
    </div>
  );
};

export default TailorModelDetail;
