import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

import articles from '../articlesData';

const ArticleDetail = () => {
  const { blob } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  let article;
  if (!isNaN(blob)) {
    article = articles.find((article) => article.id === parseInt(blob));
  } else {
    article = articles.find((article) => article.blob.toString() === blob);
  }

  if (!article) {
    return <div>Article not found</div>;
  }

  const renderListItem = (props) => {
    return (
      <li
        style={{ listStyleType: 'disc', marginLeft: '20px', lineHeight: '1.6' }}
        {...props}
      />
    );
  };

  const renderParagraph = (paragraph) => {
    const { node, ...props } = paragraph;
    return <p style={{ marginBottom: '10px', lineHeight: '1.6' }} {...props} />;
  };

  const renderH1 = (heading) => {
    const { node, ...props } = heading;
    return (
      <h1
        style={{ fontSize: '20px', marginBottom: '20px', marginTop: '20px' }}
        {...props}
      />
    );
  };

  return (
    <div className="min-h-screen bg-zinc-50">
      {article.type === 'Guide' ? (
        <div className="container px-4 py-12 md:pt-36 mx-auto dark:text-gray-400">
          <article className="max-w-4xl mx-auto">
            {/* Main Image */}
            {article.imageUrl && (
              <img
                className="w-full h-auto mx-auto mb-6 rounded-lg"
                src={article.imageUrl}
                alt={article.title}
              />
            )}
            <h1 className="mb-6 text-2xl font-semibold ">{article.title}</h1>
            {/* Article Meta Info */}
            <div className="flex items-center my-6">
              <div className="">
                <div className="text-sm text-gray-600 ">
                  Updated {article.date}
                </div>
              </div>
            </div>
            <Markdown
              rehypePlugins={[rehypeRaw]}
              remarkPlugins={[remarkGfm]}
              children={article.content}
              components={{
                li: renderListItem,
                p: renderParagraph,
                h1: renderH1,
              }}
            />
            {/* Check for Tabs */}
            {article.tabs && (
              <div className="mt-12">
                <div className="flex mb-12 border-b">
                  {article.tabs.map((tab, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer text-xl text-zinc-800 font-bold px-4 py-2 ${activeTab === index ? 'border-b-2 border-indigo-500 bg-indigo-50 rounded rounded-b-none' : 'rounded rounded-b-none'}`}
                      onClick={() => setActiveTab(index)}
                    >
                      {tab.title}
                    </div>
                  ))}
                </div>
                {activeTab !== null && (
                  <div className="mt-4">
                    <Markdown
                      rehypePlugins={[rehypeRaw]}
                      remarkPlugins={[remarkGfm]}
                      children={article.tabs[activeTab].content}
                      components={{
                        li: renderListItem,
                        p: renderParagraph,
                        h1: renderH1,
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            {/* Additional Images */}

            {/* PDF */}
            {article.specialPdf && (
              <img
                className="w-full h-auto mb-4 rounded-lg"
                src={article.specialPdf}
                alt=""
              />
            )}
          </article>
        </div>
      ) : (
        <div className="container px-4 py-12 md:pt-36 mx-auto dark:text-white">
          <article className="max-w-3xl mx-auto">
            <h1 className="mb-6 text-4xl font-bold">{article.title}</h1>
            {/* Article Meta Info */}
            <div className="flex items-center my-6">
              <div className="">
                <div className="text-sm text-gray-600 dark:text-gray-300">
                  {article.date} · {article.readTime}
                </div>
              </div>
            </div>
            {/* Main Image */}
            {article.imageUrl && (
              <img
                className="w-full h-auto mb-6 rounded-lg"
                src={article.imageUrl}
                alt={article.title}
              />
            )}
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: article.content }}
            />
            {/* Additional Images */}
            <div className="flex flex-col justify-center sm:flex-row">
              {article.extraPic1 && (
                <>
                  <div className="flex w-full h-10 sm:hidden"></div>
                  <img
                    className="w-full h-auto mx-auto rounded-lg sm:w-1/2 sm:my-8 sm:m-4"
                    src={article.extraPic1}
                    alt=""
                  />
                </>
              )}
              {article.extraPic2 && (
                <>
                  <div className="flex w-full h-10 sm:hidden"></div>
                  <img
                    className="w-full h-auto mx-auto rounded-lg sm:w-1/2 sm:my-8 sm:m-4"
                    src={article.extraPic2}
                    alt=""
                  />
                </>
              )}
            </div>
            {/* PDF */}
            {article.specialPdf && (
              <img
                className="w-full h-auto mb-4 rounded-lg"
                src={article.specialPdf}
                alt=""
              />
            )}
          </article>
        </div>
      )}
    </div>
  );
};

export default ArticleDetail;
