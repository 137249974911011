import { useState, useEffect, useRef, useCallback, Suspense } from 'react';
import Breadcrumbs from './MyModels/Breadcrumbs';
import { tests } from './ModelEvaluations/MixEvalTests';
import { useUser } from '../../UserContext';
import Spinner from '../Spinner';
import toast from 'react-hot-toast';
import clsx from 'clsx';
import { useGetModels } from '../../Hooks/react-query';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import MyModelsModelEvalResults from './ModelEvaluations/MyModelsModelEvalResults';
import { CheckIcon } from '@heroicons/react/20/solid';
import ModelNotFound from './MyModels/ModelNotFound';
import { Link } from 'react-router-dom';
import { Note } from './ModelEvaluations/ModelEvaluations';
import MyModelsErrorBoundary from './MyModels/MyModelsErrorBoundary';
import BenchmarkEvaluationSkeleton from './MyModels/BenchmarkEvaluationSkeleton';

const TailorBenchmarkEvaluation = () => {
  return (
    <MyModelsErrorBoundary>
      <Suspense fallback={<BenchmarkEvaluationSkeleton />}>
        <TailorBenchmarkEvaluationContent />
      </Suspense>
    </MyModelsErrorBoundary>
  );
};

const TailorBenchmarkEvaluationContent = () => {
  const [evaluationResults, setEvaluationResults] = useState({});
  const [showEvaluationResults, setShowEvaluationResults] = useState(false);
  const [isEvaluationInProgress, setIsEvaluationInProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const { customAxios } = useUser();
  const [selectedTests, setSelectedTests] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [pendingEvaluations, setPendingEvaluations] = useState([]);
  const [completedEvaluations, setCompletedEvaluations] = useState([]);
  const [failedEvaluations, setFailedEvaluations] = useState([]);
  const { model_name } = useParams();
  const queryClient = useQueryClient();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modelNotFound, setModelNotFound] = useState(false);

  const {
    data: models,
    isPending: modelsLoading,
    error: modelsError,
  } = useGetModels();

  if (modelsError) {
    throw modelsError;
  }

  useEffect(() => {
    if (!modelsLoading && models) {
      const foundModel = models.find((m) => m.model_name === model_name);
      if (foundModel) {
        setModel(foundModel);
      } else {
        setModelNotFound(true);
      }
      setIsLoading(false);
    }
  }, [models, modelsLoading, model_name]);

  const mixEvalRef = useRef(null);
  const needleHaystackRef = useRef(null);

  const checkModelEvaluation = useCallback(async () => {
    if (modelNotFound || !model) {
      return;
    }
    setLoading(true);
    try {
      // const response = await customAxios.get(
      //   `tailor/v1/evaluate/${model?.model_id}`,
      // );

      const evaluationResults = model.evaluation;
      // const evaluationResults = response?.data?.evaluation;

      let completedTests = [];
      let pendingTests = [];
      let failedTests = [];

      if (evaluationResults?.mix_eval?.status) {
        for (const [testName, status] of Object.entries(
          evaluationResults?.mix_eval?.status,
        )) {
          // check the test name is in the list of tests, if yes, add it, if not, ignore it
          if (tests.find((test) => test.nameToBackend === testName)) {
            if (status === 'complete') {
              completedTests.push(testName);
            } else if (status === 'started') {
              pendingTests.push(testName);
            } else if (status === 'failed') {
              failedTests.push(testName);
            }
          }
        }
      }

      completedTests = [...new Set(completedTests)];
      pendingTests = [...new Set(pendingTests)];
      failedTests = [...new Set(failedTests)];

      if (completedTests.length > 0) {
        completedTests = ['Overall Score', ...completedTests];
      }

      if (evaluationResults?.needlehaystack?.status === 'complete') {
        completedTests.push('needlehaystack');
      } else if (evaluationResults?.needlehaystack?.status === 'started') {
        pendingTests.push('needlehaystack');
      } else if (evaluationResults?.needlehaystack?.status === 'failed') {
        failedTests.push('needlehaystack');
      }

      setCompletedEvaluations(completedTests);
      setPendingEvaluations(pendingTests);
      setFailedEvaluations(failedTests);

      if (completedTests.length > 0) {
        setShowEvaluationResults(true);
        setEvaluationResults(evaluationResults);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [modelNotFound, model]);
  // }, [modelNotFound, model, customAxios]);

  useEffect(() => {
    setEvaluationResults({});
    setShowEvaluationResults(false);
    checkModelEvaluation();
    setSelectedTests([]);
    setSelectedCategories([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  useEffect(() => {
    setSelectedTests([]);
    setSelectedCategories([]);
  }, [showEvaluationResults]);

  useEffect(() => {
    if (isEvaluationInProgress && !modelNotFound) {
      checkModelEvaluation();
    }
  }, [isEvaluationInProgress, modelNotFound, checkModelEvaluation]);

  const startEvaluation = async (initialPayload = {}) => {
    if (model.state !== 'deployed' && model.state !== 'failed_undeploy') {
      toast.error('Model is not deployed. Please deploy the model first.');
      return;
    }
    let payload = { ...initialPayload };
    if (Object.keys(payload).length === 0) {
      payload = {
        model_name: model.model_name,
        eval_type: [],
        benchmarks_to_keep: [
          ...new Set(selectedTests.map((test) => test.nameToBackend)),
        ],
      };
    }

    if (payload.benchmarks_to_keep.includes('needlehaystack')) {
      payload.eval_type.push('needlehaystack');
      payload.benchmarks_to_keep = payload.benchmarks_to_keep.filter(
        (benchmark) => benchmark !== 'needlehaystack',
      );
    }
    if (payload.benchmarks_to_keep.length > 0) {
      payload.eval_type.push('mix_eval');
    }
    try {
      const response = await customAxios.post('tailor/v1/evaluate', payload);

      if (response.data.message === 'Evaluation model has been started') {
        toast.success('Evaluation model has been started');
        setIsEvaluationInProgress(true);
      }
    } catch (error) {
      if (import.meta.env.DEV) {
        console.error(error);
      }
      if (error.code === 'ERR_NETWORK') {
        toast.error('Network error. Please try again later.', {
          id: 'network-error',
        });
      }
      switch (error.code) {
        case 'servers.not_available':
          toast.error(
            'We are experiencing high traffic. Please try again in 15 minutes.',
          );
          break;
        case 'model.state_invalid':
          toast.error('Model is not deployed. Please deploy the model first.');
          break;
        default:
          toast.error('An error occurred. Please try again later.');
          break;
      }
    }
  };

  if (loading || modelsLoading) {
    return (
      <div className="flex items-center justify-center w-full min-h-screen">
        <Spinner size={'36px'} borderColor={'gray'} />
      </div>
    );
  }

  if (modelNotFound) {
    return <ModelNotFound />;
  }

  const handleCategorySelection = (category) => {
    const newSelectedCategories = selectedCategories.includes(category)
      ? selectedCategories.filter((cat) => cat !== category)
      : [...selectedCategories, category];

    setSelectedCategories(newSelectedCategories);

    const testsInCategory = tests
      .filter((test) => test.categories.includes(category))
      .filter(
        (test) =>
          !completedEvaluations.includes(test.name) &&
          !pendingEvaluations.includes(test.name),
      );

    if (selectedCategories.includes(category)) {
      setSelectedTests(
        selectedTests.filter((test) => !test.categories.includes(category)),
      );
    } else {
      setSelectedTests((prevSelectedTests) => [
        ...prevSelectedTests,
        ...testsInCategory.filter((test) => !prevSelectedTests.includes(test)),
      ]);
    }
  };

  const handleTestSelection = (test) => {
    if (
      completedEvaluations.includes(test.nameToBackend) ||
      pendingEvaluations.includes(test.nameToBackend)
    ) {
      return;
    }

    const newSelectedTests = selectedTests.includes(test)
      ? selectedTests.filter((t) => t !== test)
      : [...selectedTests, test];

    setSelectedTests(newSelectedTests);

    test.categories.forEach((category) => {
      const allTestsInCategory = tests
        .filter((t) => t.categories.includes(category))
        .filter(
          (t) =>
            !completedEvaluations.includes(t.nameToBackend) &&
            !pendingEvaluations.includes(t.nameToBackend),
        );

      const allSelectedInCategory = allTestsInCategory.every((t) =>
        newSelectedTests.includes(t),
      );

      if (allSelectedInCategory) {
        setSelectedCategories((prevSelectedCategories) =>
          prevSelectedCategories.includes(category)
            ? prevSelectedCategories
            : [...prevSelectedCategories, category],
        );
      } else {
        setSelectedCategories((prevSelectedCategories) =>
          prevSelectedCategories.filter((cat) => cat !== category),
        );
      }
    });
  };

  const handleNewTestRequest = async () => {
    setShowEvaluationResults(false);
    setIsEvaluationInProgress(false);
  };

  const scrollToElement = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClickSelectAll = () => {
    const selectableTests = tests.filter(
      (test) =>
        !completedEvaluations.includes(test.nameToBackend) &&
        !pendingEvaluations.includes(test.nameToBackend),
    );

    if (selectedTests.length === selectableTests.length) {
      setSelectedTests([]);
    } else {
      setSelectedTests(selectableTests);
    }
  };

  function Tag({ status }) {
    return (
      <span className="inline-block bg-gray-300 rounded-full px-3 md:px-2 md:py-[2px] py-1 md:text-[0.7rem]/3 text-xs text-gray-800 font-semibold">
        {status}
      </span>
    );
  }

  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans">
      <header className="sticky top-0 bg-zinc-50/95 backdrop-blur-sm z-50 border-b border-zinc-200">
        <div className=" mx-auto w-full px-4 sm:px-6 lg:px-8 overflow-x-hidden">
          <div
            className="flex items-center justify-between h-16 w-full"
            role="banner"
          >
            <Breadcrumbs model={model} />
            <div className="flex items-center gap-2 lg:gap-6" />
          </div>
        </div>
      </header>

      <div className="flex flex-col min-h-screen h-full bg-zinc-50 font-dmSans px-4 py-6 max-w-7xl mx-auto">
        {/* Grid Container */}
        <div className=" gap-4 h-full space-y-8">
          {/* First Full-Width Two-Row Div */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg min-h-[600px]">
            <div className="flex flex-col space-y-2">
              <h2 className="text-xl font-medium text-gray-800 ">
                Benchmark Evaluations
              </h2>
              <p className=" text-lg">
                {model?.model_name}{' '}
                <span className="text-sm text-gray-500">
                  {' '}
                  ({model?.base_model_data?.display_name})
                </span>
              </p>
            </div>
            <MyModelsModelEvalResults
              evaluationResults={evaluationResults}
              newTestRequest={handleNewTestRequest}
              modelName={model.model_name}
              completedEvaluations={completedEvaluations}
              failedEvaluations={failedEvaluations}
              pendingEvaluations={pendingEvaluations}
              setSelectedTests={setSelectedTests}
              startEvaluation={startEvaluation}
            />
          </div>

          {/* Second Full-Width One-Row Div */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg ">
            <h2 className="text-xl font-medium text-gray-800 mb-2">
              Evaluations Suite
            </h2>
            <div className="mx-auto  rounded-lg space-y-4 flex flex-col   ">
              {!isEvaluationInProgress && (
                <div className="pb-6">
                  <p className="text-gray-500 text-sm md:text-base font-normal max-w-[1000px]">
                    Select the tests that you would like to perform and click on
                    'Start Evaluation'. The evaluation process may take some
                    time to complete, so you can check back later to see the
                    results. To read more about the tests, follow these links{' '}
                    <span>
                      <button
                        onClick={() => scrollToElement(mixEvalRef)}
                        className="text-indigo-600 hover:text-indigo-800 underline font-mono text-sm"
                      >
                        [1]
                      </button>
                      <button
                        onClick={() => scrollToElement(needleHaystackRef)}
                        className="text-indigo-600 hover:text-indigo-800 underline font-mono text-sm"
                      >
                        [2]
                      </button>
                    </span>
                  </p>

                  {model.state !== 'deployed' &&
                    model.state !== 'failed_undeploy' && (
                      <Note>
                        Please make sure to{' '}
                        <Link
                          to={`../${model.model_name}`}
                          className="font-semibold underline underline-offset-4"
                        >
                          deploy
                        </Link>
                        {` `}your model before starting the evaluations.
                      </Note>
                    )}
                </div>
              )}
              <div className="space-y-8">
                <div className="flex flex-wrap gap-y-4">
                  <button
                    onClick={handleClickSelectAll}
                    className={clsx(
                      'text-zinc-900 bg-white rounded-md shadow-sm  px-4 py-0 mr-4 border active:border-zinc-800 hover:bg-zinc-50',
                      selectedTests.length ===
                        tests.filter(
                          (test) =>
                            !completedEvaluations.includes(
                              test.nameToBackend,
                            ) &&
                            !pendingEvaluations.includes(test.nameToBackend),
                        ).length && 'bg-indigo-200',
                      selectedTests.length === 0 &&
                        tests.filter(
                          (test) =>
                            !completedEvaluations.includes(
                              test.nameToBackend,
                            ) &&
                            !pendingEvaluations.includes(test.nameToBackend),
                        ).length === 0 &&
                        'opacity-50 cursor-not-allowed bg-white',
                    )}
                    disabled={isEvaluationInProgress}
                  >
                    Select All
                  </button>

                  {[
                    ...new Set(tests.map((test) => test.categories).flat()),
                  ].map((category) => {
                    const allTestsInCategoryHandled = tests
                      .filter((test) => test.categories.includes(category))
                      .every(
                        (test) =>
                          completedEvaluations.includes(test.nameToBackend) ||
                          pendingEvaluations.includes(test.nameToBackend),
                      );

                    return (
                      <button
                        key={category}
                        onClick={() => handleCategorySelection(category)}
                        className={clsx(
                          'text-zinc-900 rounded-md bg-white px-4 py-0 mr-4 shadow-sm border active:border-zinc-800 disabled:bg-white hover:bg-zinc-50',
                          selectedCategories.includes(category) &&
                            'bg-indigo-200',
                          allTestsInCategoryHandled &&
                            'opacity-50 cursor-not-allowed',
                        )}
                        disabled={
                          allTestsInCategoryHandled || isEvaluationInProgress
                        }
                      >
                        {category}
                      </button>
                    );
                  })}
                  <button
                    onClick={() => {
                      setSelectedTests([]);
                      setSelectedCategories([]);
                    }}
                    className=" text-zinc-900 underline-offset-4 px-2 py-0"
                    disabled={isEvaluationInProgress}
                  >
                    Clear
                  </button>
                </div>
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-8 auto-rows-fr grid-flow-row">
                  {tests.map((test) => (
                    <button
                      key={test.id}
                      className={clsx(
                        'p-4 bg-white shadow-sm border border-zinc-300 rounded-lg cursor-pointer active:bg-indigo-100 relative flex flex-col space-y-2 disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-gray-50 disabled:shadow-none hover:border-zinc-800',
                        selectedTests.includes(test) &&
                          'bg-indigo-50 shadow-md',
                      )}
                      onClick={() => handleTestSelection(test)}
                      disabled={
                        pendingEvaluations.includes(test.nameToBackend) ||
                        completedEvaluations.includes(test.nameToBackend)
                      }
                    >
                      <div className="absolute top-0.5 right-2 text-indigo-600">
                        {selectedTests.includes(test) &&
                          !pendingEvaluations.includes(test.nameToBackend) &&
                          !completedEvaluations.includes(
                            test.nameToBackend,
                          ) && (
                            <CheckIcon className="h-5 w-5 text-zinc-800 stroke-width-2" />
                          )}
                        {pendingEvaluations.includes(test.nameToBackend) && (
                          <Tag status="Evaluating" />
                        )}
                        {completedEvaluations.includes(test.nameToBackend) && (
                          <Tag status="Completed" />
                        )}
                      </div>
                      <div className="text-gray-700 font-semibold w-fit">
                        {test.displayName}
                      </div>
                      <div className="text-gray-700 flex-grow pb-2 text-left">
                        {test.description}
                      </div>
                      <div className="text-gray-700 text-xs">
                        {test.categories.join(', ')}
                      </div>
                    </button>
                  ))}
                </div>
                <div className="pt-4 flex gap-4 justify-center">
                  <button
                    onClick={() => startEvaluation()}
                    className={clsx(
                      'flex-center w-full h-12 bg-indigo-200 rounded-md shadow text-zinc-900 hover:bg-indigo-100 disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-indigo-200',
                      completedEvaluations.length > 0
                        ? 'max-w-sm'
                        : 'max-w-lg mx-auto',
                    )}
                    disabled={selectedTests.length === 0}
                  >
                    Start Evaluation
                  </button>
                </div>
              </div>
              <div>
                <p
                  className="text-gray-700 text-sm md:text-base pt-8"
                  ref={mixEvalRef}
                >
                  {!isEvaluationInProgress && (
                    <span className="font-mono text-sm">[1] </span>
                  )}
                  To read more about MixEval, follow this{' '}
                  <a
                    href="https://mixeval.github.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800 underline"
                  >
                    link
                  </a>
                  .
                </p>
                <p
                  className="text-gray-700 text-sm md:text-base"
                  ref={needleHaystackRef}
                >
                  {!isEvaluationInProgress && (
                    <span className="font-mono text-sm">[2] </span>
                  )}
                  To read more about Needle in a Haystack, follow this{' '}
                  <a
                    href="https://github.com/gkamradt/LLMTest_NeedleInAHaystack?tab=readme-ov-file"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-indigo-600 hover:text-indigo-800 underline"
                  >
                    link
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailorBenchmarkEvaluation;
