import { useState } from 'react';
import {
  failedStates,
  modelStateMessage,
  readyStates,
  trainingStates,
} from '../Deploy/modelStateConstants';
import DeploySwitch from './DeploySwitch';
import { useQuery } from '@tanstack/react-query';
import { useUser } from '../../../UserContext';
import Spinner from '../../Spinner';
import DeleteModelWaringModal from '../Deploy/DeleteModelWaringModal';
import StopTrainingButton from './StopTrainingButton';
import DeleteJobButton from '../TrainingMetrics/DeleteJobButton';

const DeployCard = ({ model }) => {
  const { customAxios } = useUser();
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  const { data: currentModel } = useQuery({
    queryKey: ['model', model.model_id],
    queryFn: async () => {
      const response = await customAxios.get(
        `tailor/v1/models/${model.model_id}`,
      );
      return response.data.message;
    },
    enabled: ['deploying', 'undeploying'].includes(model.state),
    refetchInterval: (query) => {
      return ['deploying', 'undeploying'].includes(query?.state?.data?.state)
        ? 1500
        : false;
    },
    initialData: model,
  });

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm border border-zinc-200 p-4 h-full flex items-center justify-between">
        <div className="flex flex-col gap-1 items-start">
          <h2 className="text-lg font-semibold text-zinc-800">Status</h2>
          <div className="text-sm font-medium leading-6 text-left text-gray-900 capitalize self-end flex gap-1 items-center">
            <div className="text-sm text-zinc-500 w-full">
              {modelStateMessage[currentModel?.state]?.switchLabel}
            </div>
            {(currentModel?.state === 'deploying' ||
              currentModel?.state === 'undeploying') && (
              <Spinner
                size={'10px'}
                borderTopColor={'darkGray'}
                borderColor={'rgb(238, 238, 238)'}
                borderWidth={'2px'}
              />
            )}
          </div>
        </div>
        <div>
          {readyStates.includes(currentModel?.state) && (
            <DeploySwitch model={model} />
          )}
          <div className="flex flex-col">
            {trainingStates.includes(currentModel?.state) && (
              <StopTrainingButton model={model} />
            )}
            {failedStates.includes(currentModel?.state) && (
              <DeleteJobButton model={model} />
            )}
          </div>
        </div>
      </div>
      <DeleteModelWaringModal
        showModal={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
        model={model}
      />
    </>
  );
};

export default DeployCard;
