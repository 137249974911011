import HeaderSkeleton from './HeaderSkeleton';

const BenchmarkEvaluationSkeleton = () => {
  return (
    <div className="min-h-screen bg-zinc-50 font-dmSans">
      {/* Header Skeleton */}
      <HeaderSkeleton />
      <div className="flex flex-col min-h-screen h-full bg-zinc-50 font-dmSans px-4 py-6 max-w-7xl mx-auto">
        <div className="space-y-8">
          {/* First Section Skeleton */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg min-h-[600px]">
            <div className="flex flex-col space-y-4">
              <div className="h-6 w-48 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-32 bg-gray-200 rounded animate-pulse" />
            </div>
            <div className="mt-8 space-y-4">
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="h-24 bg-gray-200 rounded animate-pulse"
                />
              ))}
            </div>
          </div>

          {/* Second Section Skeleton */}
          <div className="border border-gray-300 bg-zinc-50 p-4 rounded-lg">
            <div className="h-6 w-40 bg-gray-200 rounded animate-pulse mb-6" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {[...Array(8)].map((_, index) => (
                <div
                  key={index}
                  className="h-40 bg-gray-200 rounded animate-pulse"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenchmarkEvaluationSkeleton;
